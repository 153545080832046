import Grid from '@components/common/Grid';
import React from 'react';
import SEO from '@components/common/SEO';
import { graphql } from 'gatsby';
import { renderBlocks } from '@src/helpers';

const Page = ({ data, pageContext }) => {
  const {
    seo,
    title,
    ACFPagine: { contents },
  } = data.wpPage;
  const { language: lang, id } = pageContext;
  const isContacts = title.toLowerCase().includes('contacts') || title.toLowerCase().includes('contatti');

  return (
    <>
      <SEO seo={seo} language={lang} />
      <Grid animKey={`page-${id}`} transition>
        {!isContacts && <h1 className="col-span-12 text-md sm:text-lg tracking-headline">{title}</h1>}
        {renderBlocks(contents)}
      </Grid>
    </>
  );
};

export const data = graphql`
  query Page($id: String) {
    wpPage(id: { eq: $id }) {
      title
      ACFPagine {
        contents {
          ... on WpPage_Acfpagine_Contents_Image {
            borderBottom
            fieldGroupName
            image {
              altText
              caption
              mediaDetails {
                width
                height
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
          }
          ... on WpPage_Acfpagine_Contents_Video {
            autoplay
            borderBottom
            source
            mute
            loop
            hideControls
            fieldGroupName
            embedSource
            linkSource {
              sourceUrl
            }
          }
          ... on WpPage_Acfpagine_Contents_MediaGrid {
            columns
            borderBottom
            fieldGroupName
            media {
              embedSource
              mediaType
              source
              linkSource {
                sourceUrl
              }
              image {
                altText
                caption
                mediaDetails {
                  width
                  height
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
            }
          }
          ... on WpPage_Acfpagine_Contents_Copy {
            align
            bodyType
            title
            fieldGroupName
            copy
            columns
            paddingBottom
          }
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`;

export default Page;
